import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Input from "@mui/material/Input";
import CircularProgress from "@mui/material/CircularProgress"; // MUI Loader for loading effect

const Sidebar = ({ openNav }) => {
  const [selectedTab, setSelectedTab] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      const timeout = setTimeout(() => {
        setLoading(false); // Stop loading once the location changes after a short delay
      }, 500); // Adjust this timeout as needed (500ms should be enough)

      return () => clearTimeout(timeout);
    }
  }, [pathname, loading]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleNavClick = (to) => {
    setLoading(true); // Start loading when navigation occurs
    setSelectedTab(to);
    
    // Simulate a slight delay for navigation to show loader
    setTimeout(() => {
      navigate(to);
    }, 300); // Adjust this timeout as needed
  };

  const renderNavLink = (to, label, icon) => (
    <div
      className={`nav-link ${selectedTab === to ? "selected" : ""}`}
      onClick={() => handleNavClick(to)}
    >
      {icon && <i className={`${icon}`} />}
      <span>{label}</span>
    </div>
  );

  const renderSubNav = (title, links, icon) => {
    if (title === "Whatsapp Campaign") {
      return (
        <li className="nav-item" key={title}>
          <a
            className="nav-link collapsed"
            data-bs-toggle="collapse"
            href={`#Whatapp-nav`}
          >
            {icon && <i className={`${icon}`} />}
            <span>{title}</span>
            <i className="bi bi-chevron-down ms-auto" />
          </a>
          <ul
            id={`Whatapp-nav`}
            className="nav-content collapse"
            data-bs-parent="#sidebar-nav"
          >
            {links.map((link, index) => (
              <li key={index}>
                {renderNavLink(link.to, link.label, link.icon)}
              </li>
            ))}
          </ul>
        </li>
      );
    } else if (title === "SMS Marketing") {
      return (
        <li className="nav-item" key={title}>
          <a
            className="nav-link collapsed"
            data-bs-toggle="collapse"
            href={`#activities-nav`}
          >
            {icon && <i className={`${icon}`} />}
            <span>{title}</span>
            <i className="bi bi-chevron-down ms-auto" />
          </a>
          <ul
            id={`activities-nav`}
            className="nav-content collapse"
            data-bs-parent="#sidebar-nav"
          >
            {links.map((link, index) => (
              <li key={index}>
                {renderNavLink(link.to, link.label, link.icon)}
              </li>
            ))}
          </ul>
        </li>
      );
    } else if (title === "SMS Manage") {
      return (
        <li className="nav-item" key={title}>
          <a
            className="nav-link collapsed"
            data-bs-toggle="collapse"
            href={`#sms-nav`}
          >
            {icon && <i className={`${icon}`} />}
            <span>{title}</span>
            <i className="bi bi-chevron-down ms-auto" />
          </a>
          <ul
            id={`sms-nav`}
            className="nav-content collapse"
            data-bs-parent="#sidebar-nav"
          >
            {links.map((link, index) => (
              <li key={index}>
                {renderNavLink(link.to, link.label, link.icon)}
              </li>
            ))}
          </ul>
        </li>
      );
    }

    return (
      <li className="nav-item" key={title}>
        <a
          className="nav-link collapsed"
          data-bs-toggle="collapse"
          href={`#${title.toLowerCase()}-nav`}
        >
          {icon && <i className={`${icon}`} />}
          <span>{title}</span>
          <i className="bi bi-chevron-down ms-auto" />
        </a>
        <ul
          id={`${title.toLowerCase()}-nav`}
          className="nav-content collapse"
          data-bs-parent="#sidebar-nav"
        >
          {links.map((link, index) => (
            <li key={index}>{renderNavLink(link.to, link.label, link.icon)}</li>
          ))}
        </ul>
      </li>
    );
  };

  const navigationItems = [
    { to: "/dashboard", label: "Dashboard", icon: "bi-grid" },
    {
      title: "Reporting",
      links: [
        { to: "/report-list", label: "Report", icon: "bi-circle" },
        { to: "/live-calls", label: "Live Calls", icon: "bi-circle" },
      ],
      icon: "bi-menu-button-wide",
    },
    {
      title: "Numbers",
      links: [
        { to: "/purchase-number-list", label: "Purchase Number List", icon: "bi-circle" },
        { to: "/block-number", label: "Manage Block Numbers", icon: "bi-circle" },
      ],
      icon: "bi-journal-text",
    },
    { to: "/publishers", label: "Publishers", icon: "bi-person" },
    {
      title: "Targets",
      links: [
        { to: "/manage-buyers", label: "Manage Buyers", icon: "bi-circle" },
        { to: "/create-targets", label: "Create Targets", icon: "bi-circle" },
        { to: "/manage-targets", label: "Manage Targets", icon: "bi-circle" },
      ],
      icon: "bi-bar-chart",
    },
    {
      title: "Campaigns",
      links: [
        { to: "/create-campaigns", label: "Create Campaigns", icon: "bi-circle" },
        { to: "/manage-campaigns", label: "Manage Campaigns", icon: "bi-circle" },
      ],
      icon: "bi-layout-text-window-reverse",
    },
    // {
    //   title: "Phone",
    //   links: [
    //     { to: "/calls", label: "Calls", icon: "bi-circle" },
    //     { to: "/dial-pad", label: "Dial Pad", icon: "bi-circle" },
    //     { to: "/manage-users", label: "Manage Users", icon: "bi-circle" },
    //     { to: "/ring-group", label: "Ring Group", icon: "bi-circle" },
    //     { to: "/call-history", label: "Call History", icon: "bi-circle" },
    //   ],
    //   icon: "fa-brands fa-square-whatsapp",
    // },
    {
      title: "Settings",
      links: [{ to: "/profile", label: "Profile", icon: "bi-circle" }],
      icon: "fa-solid fa-gear",
    },
    { to: "/support", label: "Support", icon: "fa-solid fa-circle-info" },
  ];

  // Filtered navigation items based on search query
  const filteredNavItems = navigationItems
    .map((item) => {
      if (item.title) {
        const filteredSubLinks = item.links.filter((link) =>
          link.label.toLowerCase().includes(searchQuery.toLowerCase())
        );
        if (filteredSubLinks.length > 0) {
          return {
            ...item,
            links: filteredSubLinks,
          };
        } else {
          return null; // Hide the main item if none of its sub-items match
        }
      } else if (item.label.toLowerCase().includes(searchQuery.toLowerCase())) {
        return item;
      } else {
        return null; // Hide individual items that don't match
      }
    })
    .filter(Boolean); // Remove null items

  const ariaLabel = { "aria-label": "description" };

  return (
    <>
      {loading && (
        <div className="loading-overlay">
          <CircularProgress /> {/* MUI CircularProgress as the loading spinner */}
        </div>
      )}

      <aside id="sidebar" className={`sidebar ${openNav ? "active" : ""}`}>
        <Input
          fullWidth
          placeholder="Search..."
          inputProps={ariaLabel}
          value={searchQuery}
          onChange={handleSearchChange}
          style={{ marginBottom: "15px" }}
        />

        <ul className="sidebar-nav" id="sidebar-nav">
          {filteredNavItems.map((item, index) => (
            <React.Fragment key={index}>
              {item.title ? (
                renderSubNav(item.title, item.links, item.icon)
              ) : (
                <li key={item.to}>
                  {renderNavLink(item.to, item.label, item.icon)}
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      </aside>

      <style jsx="true">
        {`
          .loading-overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(255, 255, 255, 0.7);
            z-index: 9999;
          }
        `}
      </style>
    </>
  );
};

export default Sidebar;
