import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSIPProvider } from "react-sipjs";
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { updateLiveCallsCount } from '../../slices/WalletSlice';

const API_SERVER_URL = window.CAMPAIN_WWS_URL;
const RETRY_INTERVAL = 5000; // 5 seconds

const LiveCalls = ({ 
  userId = '', 
  userType = 2, 
  BargeCall,
  WhisperCall,
  ListenCall,
  onTotalLiveCallsUpdate,
  sipData = {}
}) => {
  const [extensionCalls, setExtensionCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const eventSourceRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const dispatch = useDispatch();

  const {
    sessionManager,
    connectStatus,
    CONNECT_STATUS
  } = useSIPProvider();

  const handleSSEMessage = useCallback((event) => {
    try {
      const data = JSON.parse(event.data);
      
      switch (data.type) {
        case 'connected':
          setIsConnected(true);
          setError(null);
          break;
          
        case 'update':
          const { extensionCalls, counts } = data.data;
          setExtensionCalls(extensionCalls);
          dispatch(updateLiveCallsCount(counts.extensionCallsCount));
          
          if (onTotalLiveCallsUpdate) {
            onTotalLiveCallsUpdate(counts.totalCallsCount);
          }
          
          setLoading(false);
          break;
          
        case 'error':
          setError(data.error);
          setLoading(false);
          break;
          
        default:
          console.warn('Unknown message type:', data.type);
      }
    } catch (error) {
      console.error('Error processing SSE message:', error);
      setError('Error processing server update');
    }
  }, [dispatch, onTotalLiveCallsUpdate]);

  const setupSSEConnection = useCallback(() => {
    if (!userId) {
      setError('User ID is required.');
      setLoading(false);
      return;
    }

    // Clear any existing connection
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    // Clear any pending reconnection
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
    }

    try {
      const eventSource = new EventSource(
        `${API_SERVER_URL}/api/live-calls/stream?user_id=${userId}`
      );
      
      eventSource.onmessage = handleSSEMessage;
      
      eventSource.onerror = (error) => {
        console.error('SSE Connection Error:', error);
        setIsConnected(false);
        setError('Connection lost. Attempting to reconnect...');
        eventSource.close();
        
        // Attempt to reconnect after delay
        reconnectTimeoutRef.current = setTimeout(() => {
          setupSSEConnection();
        }, RETRY_INTERVAL);
      };

      eventSourceRef.current = eventSource;
    } catch (error) {
      console.error('Error setting up SSE:', error);
      setError('Failed to establish connection. Retrying...');
      
      // Attempt to reconnect after delay
      reconnectTimeoutRef.current = setTimeout(() => {
        setupSSEConnection();
      }, RETRY_INTERVAL);
    }
  }, [userId, handleSSEMessage]);

  useEffect(() => {
    setupSSEConnection();

    // Cleanup function
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [setupSSEConnection]);

  const renderDuration = (startTime) => {
    if (!startTime) return "N/A";
    const now = new Date();
    const start = new Date(startTime);
    const durationMs = now - start;
    if (durationMs < 0) return "N/A";

    const durationSeconds = Math.floor(durationMs / 1000);
    const hours = Math.floor(durationSeconds / 3600);
    const minutes = Math.floor((durationSeconds % 3600) / 60);
    const seconds = durationSeconds % 60;

    return hours > 0 
      ? `${hours}h ${minutes.toString().padStart(2, '0')}m ${seconds.toString().padStart(2, '0')}s`
      : minutes > 0 
        ? `${minutes}m ${seconds.toString().padStart(2, '0')}s`
        : `${seconds}s`;
  };

  if (loading) return <div className="p-4">Loading...</div>;
  if (error) return <div className="p-4 text-red-500">{error}</div>;

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Live Extension Calls</h2>
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead className="thead-dark">
            <tr>
              <th>Call ID</th>
              <th>From</th>
              <th>To</th>
              <th>Status</th>
              <th>Extension</th>
              {/* <th>Duration</th> */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {extensionCalls.length === 0 ? (
              <tr>
                <td colSpan={7}>No Calls Available</td>
              </tr>
            ) : (
              extensionCalls.map(call => call.ext_number !== sipData?.aors && (
                <tr key={call.pk_id}>
                  <td className="py-4">{call.pk_id}</td>
                  <td className="py-4">{call.call_from}</td>
                  <td className="py-4">{call.did}</td>
                  <td className="py-4">
                    <span className={`badge ${call.status.toLowerCase() === 'ringing' ? 'bg-warning' : 'bg-success'}`}>
                      {call.status}
                    </span>
                  </td>
                  <td className="py-4">{call.ext_number}</td>
                  {/* <td className="py-4">{renderDuration(call.time)}</td> */}
                  <td className="py-4">
                    <div className="btn-group" role="group">
                      <button 
                        className="btn btn-sm btn-primary" 
                        onClick={() => BargeCall(call.ext_number)}
                      >
                        Barge
                      </button>
                      <button 
                        className="btn btn-sm btn-info" 
                        onClick={() => WhisperCall(call.ext_number)}
                      >
                        Whisper
                      </button>
                      <button 
                        className="btn btn-sm btn-secondary" 
                        onClick={() => ListenCall(call.ext_number)}
                      >
                        Listen
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LiveCalls;