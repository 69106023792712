import React from "react";
import "./CallSummary.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // Using copy icon for duplicate calls

function DuplicateCount({ data }) {
  if (!data || data.length === 0) {
    return <div>No Data Available</div>;
  }

  // Sum up all duplicate counts from all campaigns
  const totalDuplicates = data.reduce((sum, campaign) => sum + (campaign.duplicate || 0), 0);

  return (
    <div className="call-summary">
      <div className="summary-header">
        <i className="tio-user-big"></i>
      </div>

      <div className="table-row">
        <p style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
          <ContentCopyIcon style={{ marginRight: "8px", color: "#ff9800" }} />
          Duplicate Calls: {totalDuplicates}
        </p>
      </div>
    </div>
  );
}

export default DuplicateCount;